<template>
  <v-row>
    <v-col>
      <v-card :disabled="form.loading" :loading="form.loading">
        <v-card-title>
          New session
          <v-btn icon class="ml-2" @click="$refs.session.reset()">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-avatar color="primary">
            <v-icon dark>mdi-calendar-plus</v-icon>
          </v-avatar>
        </v-card-title>
        <v-card-text>
          <v-form ref="session">
            <v-row>
              <v-col>
                <v-menu
                  ref="datepick"
                  v-model="datepick"
                  :close-on-content-click="false"
                  :return-value.sync="form.data.date"
                  offset-y
                  min-width="auto"
                  transition="slide-x-transition"
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-text-field
                      v-model="form.data.date"
                      v-on="on"
                      v-bind="attrs"
                      label="Tanggal"
                      append-icon="mdi-calendar"
                      :rules="form.rules.date"
                    ></v-text-field>
                  </template>

                  <v-date-picker v-model="form.data.date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="$refs.datepick.save(form.data.date)">
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-btn icon @click="datepick = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-text-field
                  label="Quota"
                  v-model.number="form.data.quota"
                  type="number"
                  :rules="form.rules.quota"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-autocomplete
              label="Status"
              v-model="form.data.status"
              :rules="form.rules.status"
              :items="status_select"
              item-text="text"
              item-value="value"
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" :loading="form.loading" @click="saveData" large>
                <span>save</span>
                <v-icon right>mdi-content-save</v-icon>
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" multi-line>
      <v-icon left>
        {{ snackbar.icon }}
      </v-icon>

      {{ snackbar.text }}

      <template v-slot:action>
        <v-btn text @click="snackbar.show = false">
          <span>close</span>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import {mapActions} from 'vuex';
export default {
  data() {
    return {
      datepick: false,
      status_select: [
        { text: "Open", value: "open" },
        { text: "Closed", value: "closed" },
      ],
      form: {
        loading: false,
        data: {
          date: null,
          quota: null,
          status: null,
        },
        rules: {
          date: [(v) => !!v || "Tanggal harus diisi"],
          quota: [(v) => !!v || "Quota harus diisi"],
          status: [(v) => !!v || "Status harus diisi"],
        },
      },
      snackbar: {
        show: false,
        color: null,
        text: null,
        icon: null
      }
    };
  },
  methods: {
    ...mapActions(["addVerifySession"]),
    saveData(){
      let data = this.form.data;
      let isValid = this.$refs.session.validate();
        this.form.loading = true;

      if(isValid){
        if(this.addVerifySession(data)){
          this.form.loading = false
          this.showNotification('success')
        } else {
          this.form.loading = false
          this.showNotification('error')
        }
      } else {
        this.form.loading = false
        this.showNotification('error')
      }
    },
    showNotification(status){
      this.snackbar.show = true;
      switch (status) {
        case "success":
          this.snackbar.color = "success";
          this.snackbar.icon = "mdi-check";
          this.snackbar.text = "Data berhasil disimpan";
          break;
        case "error":
          this.snackbar.color = "error";
          this.snackbar.icon = "mdi-close";
          this.snackbar.text = "Data gagal disimpan";
          break;
      }
    }
  }
};
</script>